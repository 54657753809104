import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';
import packing from "./assets/command_fest_atlanta/packing.jpg";
import duals from "./assets/command_fest_atlanta/duals.jpg";
import artists from "./assets/command_fest_atlanta/artists.jpg";
import seating from "./assets/command_fest_atlanta/seating.jpg";
import city from "./assets/command_fest_atlanta/city.jpg";
import command_fest_tics from "./assets/command_fest_atlanta/command_fest_tics.jfif";

export function CommandFestAtlantaArticle()  {
    const { Content } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px', maxWidth: "100vh"}} className={styles.textbody}>
                        <br></br>
                        <h1>CommandFest Atlanta 2024 Review</h1>
                        <h3>Published: June 19th 2024 </h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Opinion" color="yellow"/>
                        <br></br>
                        <br></br>
                        
                        Hello Folks - I hope everyone in the states is having a sunny federal holiday!
                        <br></br>
                        <br></br>
                        I just got back from CommandFest Atlanta earlier this week and wanted to take a moment to give a little review of the event for people who have never been to a CommandFest or wanted to know more about it. For reference, the only other Magic convention I have to compare with is Magic 30 back in 2022 in Las Vegas. This particular CommandFest was hosted by a company called CoolStuffInc, so I imagine my review is more applicable to other events hosted by them. Compared to the MagicCons, CommandFest seems to be a much smaller event. I believe there were at most 500 players at this convention and you would see the same faces throughout the weekend. 
                        <br></br>
                        <br></br>
                        <Image
                            src={command_fest_tics}
                            preview={true}
                            height={"30vh"}
                            className={styles.command_fest_tics}
                        />
                        <br></br>
                        <br></br>
                        It is also worth mentioning that this event coincided with the official release date of Modern Horizons 3 so there was generally a lot of hype and excitement to play with the new cards and pull Eldrazi Titans.
                        <br></br>
                        <br></br>
                        All in all, I had a great time, but like most social events, planning the trip with your friends is key to making sure you have a solid base playgroup for the weekend. 
                        <br></br>
                        <br></br>
                        <h4>It Pays to Plan Ahead</h4>
                        <Image
                            src={packing}
                            preview={false}
                            height={"60vh"}
                            className={styles.command_fest_pics}
                        />
                        <br></br>
                        If you didn’t know, Magic Conventions are one of the best places to quickly and safely offload some of your collection, and for me this was an important part of the appeal of the event. 
                        <br></br>
                        <br></br>
                        Whether it is true bulk or piles of the $5 rares in your trade binder, conventions are the best place to either trade them up into more valuable cards or sell for cash. 
                        <br></br>
                        <br></br>
                        It pays to prepare and sort your collection ahead of time as well as setting a goal for what you want to get for your bulk. For me, being an Orzovh fan, a clean revised-edition Scrubland was my first goal, and I was hoping to get another dualland as well if the economics worked out. 
                        <br></br>
                        <br></br>
                        I emailed ahead of time to see what rates the vendors were paying for bulk commons, uncommons, rares and mythics. After looking through my boxes of unused cards, I brought 2000 commons/uncommons and 500 bulk rares/mythics that were individually worth 20 cents or less and got it all sold for $65 altogether. In hindsight, I wouldn’t terribly recommend this to most people (time-wise it was a negative), but getting shelf-space cleared at home along with the free carry-on luggage made it a little more worthwhile for me. 
                        <br></br>
                        <br></br>
                        In addition to the bulk, my trade binder carried the majority of the value that I needed to achieve my goals. You can sit down with different vendors (there were 4 at this event) and see what rates they will give you for different cards, and you can compare that to market value. If something is too low, feel free to just say “I’d rather not sell this at this price”. Most of what is left in my binder is cards that my local LGS and playgroup have no interest in anymore, so I was happy to trade at a discount of 40-60% (usually plus an extra 20-30% store credit) to consolidate my collection into a big hitter dualland. As someone without an online storefront, these rates are not terribly unreasonable in my opinion as you don’t have to deal with shipping, taxes, and of course finding someone who actually wants to pay cash for your cards.
                        <br></br>
                        <Image
                            src={duals}
                            preview={true}
                            height={"60vh"}
                            width={"45vh"}
                            className={styles.command_fest_pics_duals}
                        />
                        &nbsp;
                        <br></br>
                        If I can offer one more piece of advice, it would be to be on the lookout for other folks who are offloading their collections at the convention. This is a pretty great chance to swoop in and get a discount on cards that you may already have been planning to buy. If a player is willing to sell their foil expedition Verdant Catacombs at 60% value to the store, they will be super happy to sell to you at 70% market value (or trade)!
                        <br></br>
                        <br></br>
                        <h4>Artists and Content Creators at the Con</h4>
                        <br></br>
                        There are usually artists’ alleys at Magic Conventions and Atlanta was no different. I prepared a select group of cards for signing ahead of time. Just know that the signatures are usually $3-5 a piece and could be even more if you want them to do double signatures or rainbow signatures, etc. I also got a pretty Caged Sun schematic playmat signed by the artist!
                        <br></br>
                        <Image
                            src={artists}
                            preview={true}
                            height={"60vh"}
                            width={"45vh"}
                            className={styles.command_fest_pics_artists}
                        />
                        <br></br>
                        I’d also point out that Youtube personalities were present and I was able to chat with CovertGoBlue and Voxy as they were walking around as well. Admittedly, I wasn’t sure what to say to them but it was cool seeing them in person! 
                        <br></br>
                        <br></br>
                        <h4>Play Events</h4>
                        <br></br>
                        There were several events included with the 3-day pass that we got, as well as on-demand events, and scheduled events. I do want to make it clear that you do not have to spend money to play if you don’t want to, but for this convention in particular, the prizing was decent for paid events. Compared to Wizards of the Coast’s steeply priced prize wall at Magic 30, I was pleasantly surprised by what I was able to get at the end of the convention with my prize tickets.
                        <br></br>
                        <br></br>
                        <Image
                            src={seating}
                            preview={false}
                            height={"60vh"}
                            width={"45vh"}
                        />
                        <br></br>
                        <br></br>
                        In terms of events, I tried Mini-Masters, MH3 2-headed giant sealed, various commander and planechase pods, as well as free play (the boys and I brought playtest Legacy decks and had our own tournaments). One thing that struck me as noteworthy is that you should be prepared for a lot of down-time in between and during events waiting for the next pairings to be posted. I played less games than I thought I would each day, but I was able to do a lot of trading, chatting, and looking at merchandise and artists’ work in that time.
                        <br></br>
                        <br></br>
                        <h4>Summary</h4>
                        <br></br>
                        Overall, I am pretty satisfied with the value of the event and I would recommend going to a smaller convention like this once a year or every other year.
                        <br></br>
                        <br></br>
                        I didn’t walk away feeling like I had been a source of profit for the hosts as I did in Magic 30. The prize wall had packs fairly priced and there were several giveaways for non-foil secret lair singles and ampersand AFR promo cards. 
                        <br></br>
                        <br></br>
                        I got some unique memorabilia such as signed cards, signed playmats, and I was happy to trade my bulk for reserved list dual lands with the vendors. 
                        <br></br>
                        <br></br>
                        The events were organized well, the judges and staff were great to the players, but I personally did find myself waiting around for the next event a lot of the time.
                        <br></br>
                        <br></br>
                        <Image
                            src={city}
                            preview={false}
                            height={"60vh"}
                            className={styles}
                        />
                        <br></br>
                        Also, downtown Atlanta offers food fit for kings at decent prices. The trip cost me ~$800 for 3 nights hotel, round-trip flights, food and splitting the rented car. I spent around $200 on Magic Products/entry cost of events. 
                        <br></br>
                        <br></br>
                        Not bad for a mini-vacation!
                        <br></br>
                        <br></br>

                    </div>
                </Content>
            </Layout>
        </div>
    );
};