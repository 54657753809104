import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';


export function LimitedOTJArticle()  {
    const { Content } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px', maxWidth: "100vh"}} className={styles.textbody}>
                        <br></br>
                        <h1>Leveling Up Limited - OTJ Edition</h1>
                        <h3>Published: May. 31st 2024 </h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Limited" color="blue"/>
                        <br></br>
                        <br></br>
                        Hey Folks,
                        <br></br>
                        <br></br>
                        This month in MTG, I’ve found myself inextricably attracted to various local LGS to play Outlaws of Thunderhead Junction draft! The kicker was the small promotion from WotC to get a free premier draft on MTG Arena if you participate in 3 local events, but I’m way past that reward checkpoint (6 drafts this month and counting) and it’s been a blast. 
                        <br></br>
                        <br></br>
                        One of the more exciting sides of this little escapade has been collecting the booster packs that are the prizes for these events. I have been aiming to organically reach 24 packs from play rewards exclusively. I plan to host an OTJ draft in the distant future as a tribute to the format that I’ve come to love.
                        <br></br>
                        <br></br>
                        Naturally, since my prize pack count is directly tied to my Win/Loss at these events, I’ve been trying to put together some of the key ideas that I’ve learned from playing. Draft is one of the hardest formats in Magic since it has both a card-picking pre-game which encapsulates deck building as well as the actual piloting of your deck. I’ve split this article into a section for each.
                        <br></br>
                        <br></br>
                        (As an aside, I didn’t just walk into the stores and do well, I did my research and played nearly 20 premier drafts on MTG Arena; this is on top of my years of rare-completing the standard sets via drafting on Arena).
                        <br></br>
                        <br></br>           
                        Anyways here are some key takeaways from playing OTJ:
                        <br></br>
                        <br></br>
                        <b>Deck Building:</b>
                        <br></br>
                        1) Prioritize consistency over value. Please, please do not let yourself become enraptured in some Disney world fantasy where you are playing 5 or 4 color good stuff (although this is a valid strategy sometimes, but a vast majority of the time not). For every one game where you dominate from playing bomb-after-bomb, you will lose 2 or 3 games from simply being unable to play the game. 4/6 arena drafts I did where I got 1 win or less, I attribute to playing 4+ colors.
                        <br></br>
                        <br></br>
                        Instead, try to play two colors and splash (meaning 2-4 cards of an off-color) a 3rd only when the value of those cards heavily outweighs the natural inconsistency of playing more colors. Stick to high quality commons that you can cast consistently whenever possible.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/2/e/2e6bf35c-8763-47cc-ab2d-5dbabeb28072.jpg?1712355713"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/0/90de84c9-941b-4056-8501-ce8a948b9643.jpg?1712355286"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/1/d/1dd17cea-9e8c-4dba-b6ab-a6b9de87a306.jpg?1712355965"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        Consistency all-stars.
                        <br></br>
                        <br></br>
                        Besides color choice, which is a relatively basic concept, you must prioritize the consistency of your deck’s mana-curve. One simple example is picking a “worse” 2-drop over a good 3-drop because your deck has too much high-end already.
                        <br></br>
                        <br></br>
                        For example, if you are beginning to draft pack 3 and your deck already has 8+ 3CMC creatures, but only 3 2CMC creatures, it might make sense to pick a [Silver Deputy] over another 3 drop, especially if you are playing more than 2 colors. Although its statline is worse, it fits in some decks to help curve out properly.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/9/39d2c11d-1eb8-4768-bc61-fa8f20a69462.jpg?1712356286"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        As an aside, I wanted to point out how strong 2 CMC creatures are. The biggest issue with 3 drops is that from the point when you can cast them (turn 3), you will never be able to cast two in the same turn until turn 6, assuming you hit all your lands, which is unlikely. 2 drops on the other hand, are amazing on turn 2, and can be played in tandem with other cards throughout the game. Effective 1 drops are even more flexible (see snakeskin). 3 drops+ are clunky because you will only be able to play one a turn most of the time. This means that if you play a clunky 4 drop and it gets removed by a 2 CMC spell by your opponent, you have lost a lot of tempo. Sometimes this tempo will cost you the game. In general, I would prioritize the consistency of playing lower drops over the “value” of higher drops. This is part of the reason why multi-mode cards such as the spree cards are great. Just make sure they are worth playing without paying the full costs. When deckbuilding, slot multi-mode cards into the CMC you would be most likely to play them in. 
                        <br></br>
                        <br></br>
                        One final aside about consistently playing a 2 CMC drop (hopefully with 3 power: 3/1 commons for 2 mana are my favorite in draft). Getting on the board sets you up to punish less optimized decks or missed land drops from your opponent (lands are the best and worst part of Magic). If they fail to set up their board in the first 3 turns of the game, a 2-drop followed by a 3-drop combined with turn 4 and turn 5 removal can often just outright end the game or put your opponent in an unrecoverable position. An early 2-drop can get the most value out of its stat-line just from the fact that it's been on the board for longer than a more "impactful" 4+ drop.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/f/ef32a5f8-f69d-47dc-a800-4f0ddf4eada5.jpg?1712355371"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        Early drops benefit from having more turns in the game to apply their statline.
                        <br></br>
                        <br></br>
                        2) If your deck falls apart without one key card, then it’s not a good gameplan. You want to pick cards that are generally good by themselves or generally good with most of the other cards in your deck. For example, let's say you draft a [Vaultborn Tyrant] in pack 1 pick 1 and plan to pick all the mana generators you come across. In the games where you don't draw your [Vaultborn Tyrant], what will you do with all that ramped mana? In this scenario, I would make sure the deck continues to curve properly throughout the midgame and look for other cards (spree or X cards) that might be a good way to drain your mana. If you don’t win any games except for the ones where you draw your bombs, that might be indicative that you didn’t play much of a part in making your deck better.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/6/2/62b3f560-262b-4bc3-9aef-535fd7082c28.jpg?1712352885"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        Ask yourself how you can contribute to increasing your winrate in the cases when you don't draw your bombs.
                        <br></br>    
                        <br></br>
                        As an aside, with the advent of play boosters where there can be up to 4 rares (read: potential bombs), 2 mana thoughtseize-type cards that in previous sets were considered unplayable are actually really good in OTJ. Power level has increased and a lot of cards nowadays replace themselves on cast, so we are less often completely top-decking. This is the stage that [Binding Negotiation] appears on, becoming quite the sniper for game-ending threats. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/1/c/1c4c26b9-981f-47cf-b0f4-769e788d9537.jpg?1712355546"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        3) Hate-draft if you need to, while doing in-person drafts (doesn’t apply to arena drafts). Don’t wheel cards that you have extremely low chances of winning against like [Vaultborn Tyrant] and [Bonny Paul, Clearcutter]. 
                        <br></br>
                        <br></br>
                        <b>Piloting:</b>
                        <br></br>
                        1) Like in all other formats, you must make a determination on who the beatdown is. The beatdown is the player who must win early. The beatdown’s opponent has inevitability - their deck will win if the game becomes drawn out. Note that the beatdown status can change throughout the game, especially if both players are playing midrange. 
                        <br></br>
                        <br></br>
                        On that note, in Bo3, once you know what your opponent’s win condition is, you better prepare for it. 
                        <br></br>
                        <br></br>
                        I’ll tell an anecdote here. My opponent killed me in game 1 by sacrificing a creature with [Lotus Ring] to reach 11 mana to cast [Crackle with Power] for X=3, dealing 15 damage directly to my face. After I won game 2, he attempted to do the same thing in game 3, but I held up a counterspell for it. I won the match in a nail clincher.
                        <br></br>
                        <br></br>
                        In this example, I thought I was the beatdown in game 1 until I learned of his direct-damage-to-face spell. To deal with their inevitability, I kept up a counterspell.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/0/2/02267717-66e0-41f7-8009-75586a4aa4be.jpg?1712352932"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/f/4/f4a887d8-4afa-4551-9db2-dd90b95a68c0.jpg?1712353689"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/5/4/54ee0802-62c1-46ac-907e-82ace59fbddb.jpg?1712353566"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        11 mana to pay x=3 [Crackle with Power] (15 damage) getting countered by having 2 mana up.
                        <br></br>
                        <br></br>
                        2) At all costs, do not set yourself up to be blown out by a combat trick, yours or theirs. Never attack or defend if you would lose the game to a combat trick. Even if you have a combat trick, I prefer to use it in response to my opponents rather than be blown out by them responding to ours.
                        <br></br>
                        <br></br>
                        2.5) Take advantage of forced double blocks. One of the best things about playing a 5-drop in draft is that it typically requires two blockers to trade. Try to maneuver yourself into a position where your opponent must block with two or more creatures (low life total), and then get yourself a juicy 2-1 or 3-1 by removing one of the blockers. If they have mana up though, consider just taking the trade.
                        <br></br>
                        <br></br>
                        At the same time, consider if over-blocking is a good choice to play around what can be cast with their open mana. (I’ll trivially point out here that you should generally attack before casting permanents in main phase 2).
                        <br></br>
                        <br></br>
                        These are the main combat tricks to play around based on your opponent's open mana.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/1/3/133fbdec-0d00-433f-9015-5eb091126e3a.jpg?1712355994"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/7/6/76a31968-ba6d-4c01-838f-4cb8c64e73fb.jpg?1712355363"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/0/3/03709166-164a-4075-ad0d-ea3b516ab771.jpg?1712355676"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        3) Just because you can respond, doesn’t mean you should. Think about whether it is worth it to remove a creature or save a creature. Sometimes card advantage alone isn’t enough of a justification. (Is this removal needed for a truly game-ending threat?)
                        <br></br>
                        <br></br>
                        4) Hedge risks properly. More often than not you will have to take a risk to achieve an optimization or a game win. If you are winning overwhelmingly, don’t cast extra creatures if you already have lethal on board. If you don’t have lethal on board and are deciding between it, try to force them to answer or they lose. The more turns they take(cards drawn) the more likely they will find an answer.
                        <br></br>
                        <br></br>
                        5) Revealing information. Pay attention to what your opponent does or doesn’t do. As I’ve played more, it becomes quite easy to tell that they have a combat trick up if they are leaving exactly 2 mana up each turn, thinking before untapping, etc. Ask yourself what is so complex that my opponent has to pause when doing a game action. On the flipside, try to minimize how much non-verbal information you are leaking (or use it to throw things off if you are a genius, i.e. pausing at the end step even if your hand is just lands). 
                        Also, I know we aren’t playing cutthroat qualifiers with giant prize pools ($5 for a pack is nice though), but there’s no need to explain to your opponent how your deck works in between games. If you are playing best-of-3’s, there’s no need to show your hand after you’ve lost the first match and say “I would have won next turn”.
                        <br></br>
                        <br></br>
                        <b>My favorite Commons in OTJ:</b>
                        <br></br>
                        <br></br>
                        <i>White:</i>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/0/1/019d539f-04c2-43f1-8677-6d6fbb0e94f7.jpg?1712355354"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Sterling Keykeeper]
                        <br></br>
                        Tappers are always amazing late game because you can tap at your opponents end step and then tap again on your turn to make a big tempo swing.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/0/90de84c9-941b-4056-8501-ce8a948b9643.jpg?1712355286"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Holy Cow]
                        <br></br>
                        Flying is great in limited, you can use flash as soft removal for big threat two drops, and the scry 1 and life is a nice touch.
                        <br></br>
                        <br></br>
                        <i>Black:</i>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/2/e/2e6bf35c-8763-47cc-ab2d-5dbabeb28072.jpg?1712355713"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Vault Plunderer]
                        <br></br>
                        Replaces itself and has a great statline. I would run as many as 3 or 4 of these in one deck.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/3/93b17d09-974a-4e33-b14d-5fe4230ab241.jpg?1712355541"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Ambush Gigapede]
                        <br></br>
                        This card is almost always a 2-for-1’s with its ETB and its high power. I usually play 1 or two in every black deck I draft.
                        <br></br>
                        <br></br>
                        <i>Red:</i>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/2/324c0af5-7cdf-4c71-84cc-6349f10e0d66.jpg?1712355780"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Irascible Wolverine]
                        <br></br>
                        Not quite as good as straight up drawing a card, but it can be plotted to double-spell and has one more toughness. Solid for top-decking in the late game.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/9/e9a50b7a-8741-4520-8d45-8e6b128c2628.jpg?1712355733"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Deadeye Duelist]
                        <br></br>
                        This card can really put a clock on your opponent in a stalled board and if you are ahead, it is massive pressure. It also naturally synergizes with a lot of crime cards in the set and allows you to get crime triggers every turn cheaply. It also blocks decently well.
                        <br></br>
                        <br></br>
                        <i>Green:</i>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/1/3/133fbdec-0d00-433f-9015-5eb091126e3a.jpg?1712355994"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Snakeskin Veil]
                        <br></br>
                        Snakeskin is probably hands down one of the most efficient cards in the format. Keep your eyes peeled for 1 forest left untapped by your opponents. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/2/e/2e0e27f9-dc2c-4366-b810-3e8d0bdff8c3.jpg?1712355900"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Cactarantula]
                        <br></br>
                        Great statline with reach, comes at a discount if you control a desert, often draws you a card if removed.
                        <br></br>
                        <br></br>
                        <i>Blue:</i>
                        <br></br>
                        Probably the weakest color in the format in terms of blue commons, but it can be a good supporting color. There are a lot of powerful uncommon blue cards though, such as Slickshot Lockpicker, This Town Ain't Big Enough, and Plan the Heist.
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/a/4/a4be8e47-9006-4770-8d99-68a684064a43.jpg?1712860592"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Jailbreak Scheme]
                        <br></br>
                        It's decent removal with the high-upside of being very good at closing out games. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/4/9/49f12760-ff07-4c9f-a7a9-1e64bd3a9adf.jpg?1712355452"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Loan Shark]
                        <br></br>
                        This card is decent since it replaces itself if you plot it, but skipping turn 4 can be backbreaking.
                        <br></br>
                        <br></br>
                        <i>Colorless:</i>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/e/9e5bfcf5-6e5c-47fe-af6c-6b18938261c6.jpg?1712356295"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        [Sterling Hound]
                        <br></br>
                        I think [Sterling Hound] is a super slept-on card. 3/2 is a decent statline at 3 mana and the surveil 2 can really set up the rest of your game. Despite this, I often see it as late as pick 10+.
                        <br></br>
                        <br></br>
                        I’ve felt and also heard countless good things about the ping-lands in this format. It’s great that they do something meaningful (trigger your crime cards) when you play them. I think it’s part of what makes drafting the set so much fun - there are on-average more high impact cards per pack with the inclusion of these lands. 
                        <br></br>
                        <br></br>
                        <b>Conclusion</b>
                        <br></br>
                        As a final note, I know I have just brushed the surface of playing OTJ draft but hopefully you walk away with at least one new idea. Even if you don’t, my hope is that your appreciation for the format and your appreciation for striving to become a better player has proliferated.   
                        <br></br>
                        <br></br> 
                        What do you think?
                        <br></br>
                        <br></br>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};