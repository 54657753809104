import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';


export function BecomingAJudge2024()  {
    const { Content } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px', maxWidth: "100vh"}} className={styles.textbody}>
                        <br></br>
                        <h1>7 Most Interesting Rules I Learned While Becoming a L1 Judge in 2024 </h1>
                        <h3>Published: Sep. 30th 2024</h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Guide" color="green" />
                        <br></br>
                        <br></br>
                        <h4>Introduction</h4>
                        Hey folks,
                        <br></br>
                        <br></br>
                        The third quarter has flown by as Duskmourn hits the market favorably and Magic continues to be the machine that won’t slow down! A lot has happened this year, but for me personally, a new local game store opened up that I’ve had the honor of running Magic events for.
                        <br></br>
                        <br></br>
                        One of the store's major goals has been to hold more competitive Magic events and a real obstacle for that has been finding proper, qualified judges. In a high stakes tournament, what happens when players have a dispute about rulings or when a player accidentally or purposefully cheats? In a competitive rules enforcement setting, there is a wide spectrum of penalties and procedures that should really be followed with proper decorum. To that end, I have spent roughly the last two months studying and testing to become an official judge!
                        <br></br>
                        <br></br>
                        Although Wizards of the Coast no longer has a relationship with Judge Academy or any judging initiative, there still exists community run programs like Judge Foundry, which (from what I’ve gathered) contains a lot of the original judging folks. There are several judging levels from L1 to L5, with L1 generally representing the ability to run a good Friday Night Magic event and L2/L3+ being conducive to handling more competitive events. The general process for becoming a judge involves getting in touch with one of the regional coordinators (thanks Dave!), who will help you find a mentor (thank you Jenn!). You work with the mentor going over several key policy and rules documents until you feel ready to test:
                        <br></br>
                        <br></br>
                        <ul>
                            <li> <b>Magic Comprehensive Rules (MCR)</b>: The official 300 page document outlining game concepts, cards, zones, turn structure, and all variety of abilities, triggers, replacement effects, etc. </li>
                            <li> <b>Judging at Regular Rules Enforcement (JAR)</b>: the procedures for handling issues, unwanted behaviors, and serious problems at noncompetitive events like FNM, weekly events, etc.</li>
                            <li> <b>Infractions and Penalties Guide (IPG)</b>: Although not a part of the L1 test, this document covers the general philosophy of giving out penalties, the levels of seriousness, and how to correct situations in high level play.</li>
                        </ul>
                        I spent many train rides with pen in hand and tiny-text printouts of sections of these documents as well as several sessions in judge class at my mentor’s LGS with other judges or judges-to-be. I’ve come back to Magic and played extremely consistently for the last few years, but this is the first time I’ve formally attempted to study the book.   
                        <br></br>
                        <br></br>
                        From this period of research and review, I’m happy to present 7 of the most interesting rules or interactions I came across in my studies!
                        <br></br>
                        <br></br>
                        <h5>7) The “Thoughtseize Rule” in Competitive Magic</h5>
                        In the course of playing Magic, either through dexterity errors or failing to reveal a drawn or searched card “e.g. search for an instant”, a player might move a card into a zone that only contains hidden cards, most commonly their hand. This is a pretty serious issue because the opponent is unable to confirm or check which card was moved into your hand, resulting in a window during which you could have essentially put any card into your hand. When such an error occurs, we issue something called a Hidden Card Error - the fix is relatively harsh, but necessary to prevent “just trust me” moments. 
                        <br></br>
                        <br></br>
                        <b>Remediation</b>: The opponent gets to see all cards in the hidden zone and pick ANY card to return to the proper zone. So if we drew an extra card during our draw step for example, and the card is already in our hand, we have committed a Hidden Card Error and with the assistance of a judge, our opponent is allowed to see our entire hand and choose and return a card of their choice to be shuffled into the randomized portion of our library. Since the opponent has no information but made no mistakes, they should not be penalized.
                        <br></br>
                        <br></br>
                        This struck me as interesting because I’ve only really competed in the weekly tournaments at various local game stores, which are run at regular rules enforcement. 
                        <br></br>
                        <br></br>
                        <h5>6) Layers are notorious for being complicated</h5>
                        My mentor taught me a simple mnemonic to remember: in the tune of twinkle, twinkle, little star, sing CC - TT - CAP. Which stands for:
                        <ul>
                            <li>Copy</li>
                            <li>Control</li>
                            <li>Text</li>
                            <li>Type</li>
                            <li>Color</li>
                            <li>Abilities</li>
                            <li>Power/Toughness</li>
                        </ul>
                        
                        Layers always apply in order from lowest (Copy) to highest (Power) and if two layer abilities are in the same layer, timestamps are used unless there is a dependency (which warrants an entire discussion of its own). 
                        <br></br>
                        <br></br>
                        There’s tons of examples of these rulings, but for example, both [[Magus of the Moon]] and [[Harbringer of the Seas]] turn your nonbasics into either mountains or islands respectively. If both are on the battlefield, since they type changing effects, the later timestamp would determine what all nonbasic lands are. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/7/c/7c9bd75c-9606-4607-bfa6-d6acdee12820.jpg?1619397276"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/0/0/00212714-a410-4cbc-bf1c-f90d7d77378c.jpg?1717470489"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        <h5>5) Replacement effects are something that I had never really known were a subset of rules.</h5>
                        Replacement effects are typically worded with very specific vocabulary: 
                        <ul>
                            <li>“instead”</li>
                            
                            <li>“[permanent] enters as…”</li>
                            
                            <li>“[permanent] enters with…”</li>
                            
                            <li>“[permanent] enters with…”</li>
                            
                            <li>"skip [something]"</li>
                        </ul>
                        In games of commander where big fancy enchantments with sweeping game rule changes are staples, it’s fairly typical for two permanents to replace the same thing. For example [[Dauthi Voidwalker]] and [[Rest in Peace]] might both be in play, both of which replace cards going to the graveyard.
                        <br></br>
                        <br></br>
                        How do we decide which replacement effect to use?
                        <br></br>
                        <br></br>
                        It turns out the answer depends on who controls what is being affected. It is actually as simple as this:
                        <br></br>
                        <br></br>
                        If you control the permanent or card, then you choose which replacement effect to apply and in the orders of your choice. So for our [[Dauthi Voidwalker]] and [[Rest in Peace]] scenerio, if I cast a draw spell like [[Brainstorm]], as it resolves, I get to choose whether it goes under [[Dauthi Voidwalker]] with a void counter or to exile under [[Rest in Peace]].
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/d/c/dce5db87-4a78-4b8d-b5c2-918ccd1ba4e3.jpg?1626095427"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/7/37c2b1d1-faa0-40fd-82f4-216604ce7635.jpg?1562784882"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        Any single replacement effect can only be applied once to an event happening, and it's also worth noting that for damage replacements, e.g. when both [[City on Fire]] (triple damage done) and [[Mechanized Warfare]] (+1 damage) are on the battlefield, the one receiving the damage decides on the order of replacements. So a lighting bolt could do either 12 or 10 damage. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/6/f/6f455cc1-a822-44ef-ba7c-bfcff69bd45e.jpg?1682204100"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/b/a/ba2d026f-bdca-4376-9d70-c81c707598e8.jpg?1674421169"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        <h5>4) Combat is pretty confusing!</h5>
                        Between trample, deathtouch, first strike and damage modifiers for attackers as well as indestructible and protection on blockers, we have lots of interesting rules and sub-rules. 
                        <br></br>
                        <br></br>
                        Combat is especially important for formats like booster draft where games are often decided by the timing of combat tricks used on mostly-common rarity or vanilla, keyword-soup creatures.
                        <br></br>
                        <br></br>
                        The main rule that is commonly overlooked is that for a trampling creature, lethal damage must be applied before additional damage can “go through” to the defending player. However deathtouch makes the lethal damage requirement just 1. That means that if a 4/4 with trample and deathtouch attacks into a 0/6 wall with indestructible, the attacking player can simply assign 1 damage to the 0/6 and the rest goes through!
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/4/e41cf82d-3213-47ce-a015-6e51a8b07e4f.jpg?1572490640"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/7/3/73b49065-0a46-4813-a721-71d718e73d18.jpg?1562737811"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        <h5>3) State based actions are only checked when a player receives priority</h5>
                        State based actions are game actions that are always automatically being checked over the course of a game. When certain criteria are met, they happen automatically and don’t use the stack. For example, a creature with 0 toughness will automatically be put into the graveyard. 
                        <br></br>
                        <br></br>
                        It turns out that although SBA are automatic, they are only checked when a player has priority. That means that when a spell is being cast that has multiple steps while resolving, state based actions aren’t checked until after the entire card is resolved. For example if we control [[Ashaya, Soul of the Wild]] and we sacrifice all our lands to [[Scapeshift]] and we get at least 1 land, although Ashaya temporarily became a 0/0, State Based Actions weren’t checked in the middle of [[Scapeshift]] resolving since no player had priority and Ashaya would not be destroyed.
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/7/4/74943390-d25f-47cb-90bb-cbf70c87f4a2.jpg?1604198513"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/8/4/84829605-50eb-455d-a236-ebfa11e883c5.jpg?1562879722"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        <h5>2) Face down cards have very bizarre interactions!</h5>
                        In standard, there are now three keywords for getting facedown cards onto the battlefield - cloak, disguise, and manifest dread! 
                        <br></br>
                        <br></br>
                        Face down cards are nameless, so a card like [[Maelstrom Pulse]] that destroys all permanents with the same name won’t destroy all of your disguised creatures.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/5/8/58beea98-bafd-46bf-8aec-d98893019374.jpg?1561967352"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/9/e9f896d4-34b4-407d-be7e-bf7a69aaf811.jpg?1561758369"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        Additionally, it’s required that players maintain the order of their facedown cards for their opponents (once again, if you mix up/randomize your face down creature cards in a competitive REL game, you will be required to show them all to your opponent).
                        <br></br>
                        <br></br>
                        Although less egregious than requiring players to maintain the order of your graveyard for formats that contain cards before the Urza’s Saga set, I still found this rule notable.
                        <br></br>
                        <br></br>
                        The main rubbing point with face down cards is that a lot of rules text isn’t printed on the card, which is reasonable I guess since there are a lot of face-down card rules.
                        <br></br>
                        <br></br>
                        One thing that tripped me up while studying was that if you copy a face-down card, your copy doesn’t have a front face and has only the properties of the back face!
                        <br></br>
                        <br></br>
                        <h5>1) You can always ask a judge for rules clarification</h5>
                        Lastly, I learned that if I ever have a question during an event I am playing in, I should ask a judge. I can ask at the table, or request to ask my question away from the table if I don’t want to reveal information. 
                        <br></br>
                        <br></br>
                        Before becoming a judge myself, the role and responsibilities of a judge were never really openly announced before each event, so judges just seemed like staff to me. I learned that judges have the responsibility of being neutral and are generally not obliged to step into any matches unless asked by players. Judges are neutral arbiters that try to help and make games as fair as possible, so don’t be afraid to ask a judge anything!
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/0/f/0fc51899-3970-416b-b7de-fadbc9678955.jpg?1562782718"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        <h5>Conclusion</h5>
                        After completing the judge test, 25 questions mostly about the MCR and JAR, I am now a L1 judge! I will continue to study for the next year or so and then go for L2, which tests competitive rules infractions (in the IPG) as well as more complex scenarios from the MCR.
                        <br></br>
                        <br></br>
                        Also I learned you can do judging as a bit of a gig, traveling to conventions across the country to work as a judge!
                        <br></br>
                        <br></br>
                        It has been a really rewarding experience that adds another layer (ha) to my Magic experience! I highly recommend tournament organizers and experienced players to get the certification themselves to really deepen their game knowledge across formats. 
                        <br></br>
                        <br></br>
                        -xTaq
                        <br></br>
                        <br></br>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};