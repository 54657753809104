import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';


export function PickingADeckPauperEdition()  {
    const { Content } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px', maxWidth: "100vh"}} className={styles.textbody}>
                        <br></br>
                        <h1>Picking a Deck - Pauper Edition </h1>
                        <h3>Published: Oct. 20th 2024</h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Pauper" color="purple" />
                        <br></br>
                        <br></br>
                        <h4>Introduction</h4>
                        Hey folks,
                        <br></br>
                        <br></br>
                        As I’ve come around the LGS more and more, I’ve noticed that playgroups with high passion and enthusiasm tend to attract interest. This month of October, I’ve watched the Pauper players gather and compete week after week, as well as chat, brew, and discuss their format with a level of nuance and real zest that makes me wonder if I’m missing out on a diamond in the sand.
                        <br></br> 
                        <br></br>
                        I’ve decided to give it a shot by picking a deck.
                        <br></br>
                        <br></br>
                        Picking a deck to build is one of the most personal experiences that Magic has to offer, and it takes a lot of self-reflection about what you enjoy, what you want to try, and what you want to get out of the deck.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/5/a/5af43ceb-56d2-47d4-ab43-853338ab293c.jpg?1712354173"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/0/8/08c9890f-7081-42f6-89eb-35c83911b443.jpg?1624661911"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        For my part in Magic, I’ve always enjoyed deck building around cool ideas (I’m a bit of a Timmy) and executing them in tight, consistent games (I’m a bit of a Spike). This has lended me to higher power EDH for the deck building, limited formats for on-the-fly deck building, and I’ve dabbled in Standard, Pioneer, and Modern for close games built around interacting at key moments or just building advantage over slight adjustments in play.
                        <br></br>
                        <br></br>
                        Unfortunately, a lot of my favorite cards are not “common” rarity cards in that they tend to have very specific or very powerful effects. Amulet of Vigor in Modern, Skullclamp in EDH, Greasefang in Pioneer. Fundamentally, these cards are all meant to be abused in very specific build-arounds. Nonetheless, pauper certainly checks off the box for tight play and it contains many of Magic’s all star iconic cards in the form of powerful interaction. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/0/7/0790fb8c-7ff3-4681-a2c0-f16642f4f94b.jpg?1698988022"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/6/3668996e-659d-413b-84e6-9f3099518d7f.jpg?1682693957"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/4/9455cb27-89d3-4bb1-8af8-08c7712c2915.jpg?1654570167"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        So here I am with a list of popular pauper decks open, trying to pick a deck that resonates with my playstyle and is interesting enough to invoke my wallet (luckily one of the draws of pauper is its low cost). The criteria I am looking for in a partner, I mean a deck is:
                        <br></br>
                        <br></br>
                        <ul>
                            <li> It should be able to interact effectively with my opponents </li>
                            <li> It should be a deck that has a unique identity clearly demarcated from other formats; For this reason, I’d like to stay away from hard control, hard aggro, and all-in combo type decks</li>
                            <li>It should have a high learning curve or many different lines to choose from - I enjoy the challenge of learning toolbox decks.</li>
                        </ul>
                        <h5>Option 1: Simic Turbo Fog!</h5>
                        At a glance, the turbo fog deck really called out to me. It performs pseudo board wipes every turn by blanking your opponents board, it has an interesting play pattern that feels like control but has its own twist, and it is certainly not an archetype I've ever seen in the other formats I play!
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/4/0/40ebe935-ccf9-435e-8fe8-53bcbf3526e7.jpg?1562906929"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/f/6/f6a9fa51-78c3-42e6-8c2e-39658f59ed87.jpg?1562202265"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        However, the games look like they would take very long and that may not be suitable for someone new to a format; It also seems that your opponent probably wouldn’t enjoy the match too much and I’m trying to make friends. 
                        <br></br>
                        <br></br>
                        I will note that I’ve never been strong on making sure “everyone has fun” because I’m a big proponent of playing many strategies and I enjoy the dynamics of different types of matchups. A 40/60 odds game is still an interesting game in my opinion, for both players. Magic is not about winning every game; it’s about winning more when you are supposed to lose and losing less when you are supposed to win. Still, the turbo fog deck is borderline egregious in the way it attempts to ignore your opponent turn after turn!
                        <br></br>
                        <br></br>
                        
                        <h5>Option 2: Abzan Food Gates!</h5>
                        So I continue my perusal of the metagame decks. Now might be a good time to mention that I’m loath to jump in on what appears at a glance to be the top deck, Grixis Affinity, mostly out of feeling like an outsider to the format. Perhaps this is self-limiting and self-imposed, but I think I would feel like a bit of a hack jumping in with the most meta deck. 
                        <br></br>
                        <br></br>
                        And behold - what stood out to me most was gates! A deck that finds its proper power level in this format but is too slow for every other format where tapped lands are near-unplayable. The power of [[Basilisk Gate]] grows over the course of these grindy matches as we begin pumping our 1/1 rabbits to become 8/8s and beyond! The canonical variant is the Azorious squadron hawk version that looks solid, but I just traded away my FNM playset a while ago. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/3/935f3dfa-7d8d-459a-8ac2-37892cb9545f.jpg?1717190870"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/c/4/c4ceb589-c741-44ac-98c8-3d997953ee61.jpg?1674138128"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/7/9/793d4978-9e00-453d-8dc2-6d51ad6c26b7.jpg?1674138203"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        While scrolling further, I came across an Abzan Food-Gates variant (my favorite color combination in Magic), that has a strong synergistic focus on sacrificing value-based artifacts! [[Deadly dispute]] is of course, an incredibly powerful card, and when combined with [[Cauldron Familiar]] and various foods printed across sets, I think this deck looks like a lot of fun to play and grind out incremental value in long games.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/7/3/7373fe95-ad1c-44b9-8c7f-464ce8cbffc6.jpg?1627704524"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/a/9a539a23-8383-4525-82dd-acfe1d219fe9.jpg?1572490099"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/b/3b5349db-0e0a-4b15-886e-0db403ef49cb.jpg?1721426825"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/b/3b46aacf-b31a-4380-9e4b-82795fbaba3b.jpg?1686970202"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        <a href="https://sorinslist.com/deck/MirranUnicornInstant">Decklist</a>
                        <br></br>
                        <br></br>   
                        Abzan Food-Gates doesn’t have so much individual interaction outside of 3 removal spells, but it does have board wipes via [[Toxin Analysis]] + [[Crypt Rats]]! And board wipes are not terribly prevalent in Pauper since most destroy all effects are locked behind rares. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/0/e/0eda1aff-c1f4-4171-a800-605396cc8168.jpg?1706241766"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/6/d/6dccdaba-7504-4df6-a079-d7fe450934ab.jpg?1675649302"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        <br></br>
                        Also the idea of winning on the spot with a 10+ damage crypt rat blast seems pretty exciting haha. 
                        <br></br>
                        <br></br>
                        Altogether, this deck is $85, with most of the value being in three $10 old, sideboard cards that remove 2 artifacts/enchantments - Dust to Dust. 
                        <br></br>
                        <br></br>
                        Now that I’ve picked the deck, I’m looking forward to putting it together and dropping by the local game store to give the format a try!
                        <br></br>
                        <br></br>
                        - xTaq
                        <br></br>
                        <br></br>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};