import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';


export function HandlingUnfunArticle()  {
    const { Header, Content, Footer, Sider } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px', maxWidth: "100vh"}} className={styles.textbody}>
                        <br></br>
                        <h1>Handling "Unfun" Decks</h1>
                        <h3>Published: Jan. 18th 2024 </h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Opinion" color="yellow"/>
                        <br></br>
                        <br></br>
                        If you’ve played the game of Magic: the Gathering, then you’ve almost certainly felt “unfun” before - there are times when we are locked down, pushed out of the game and unable to make a meaningful impact on the board state. Whether it’s from mass land destruction, a “stax” strategy, an opponent taking near-infinite turns or some other engine-solitaire, or simply being attacked again and again to the point where we must make suboptimal plays just so that we can stay alive, Magic can have some very unfun scenarios.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/d/1/d1af92a1-a86a-43eb-82fd-394f1168111e.jpg?1562937149"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        The infamous “stax” strategy of resource denial.
                        <br></br>
                        <br></br>
                        What can we as players do to make these situations less painful? 
                        <br></br>
                        <br></br>
                        In the context of 4 player commander, there are obvious responses such as:
                        <br></br>
                        <br></br>
                        - “You should really play more interaction!”  or
                        - “Poor threat assessment is what led to player X’s dominating board state.”
                        <br></br>
                        <br></br>
                        However, the reality is that Magic is a game with an element of chance and there will be times when there are no answers to a game-making play, even in a 4 player match. There will be times when we have made no glaring mistakes, yet we find ourselves in a very compromised position, peering into the abyss. When these situations inevitably happen, we may experience feeling “bad”.
                        <br></br>
                        <br></br>
                        Just as “flying” is an evergreen keyword for “creatures with flying cannot be blocked except…”, feeling “bad” is what players often use as the keyword for “lack of agency on the outcome of my game, resulting in a feeling of wasted time, hopelessness, or even darker thoughts…”.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/a/a/aac00055-640e-4749-8d23-d242e6d0b23a.jpg?1594736330"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        Sometimes, we find ourselves not having fun.
                        <br></br>
                        <br></br>
                        Today, instead of focusing on how we can make our decks more resilient to threatening situations though better deck building (which I do plan to cover in separate article(s)), I’d like to begin with a conversation about how we should approach the process of losing.
                        <br></br>
                        <br></br>
                        I’m sure we’ve all heard before a player saying “I don’t care about winning.” In a healthy Magic environment, the full text should read: “I am always trying to win; I hope my opponents are trying their best to win; As long as these two conditions are met, I don’t care about winning.” A good game of Magic is characterized by the simple joy of strategizing, exchanging blows, and maneuvering with incomplete information.
                        <br></br>
                        <br></br>
                        How then do we approach a situation where everyone is trying to win, but actually, only one player can meaningfully make progress towards winning? To answer this question, I often find it is helpful to characterize scenarios on an axis to compare the extremes. 
                        <br></br>
                        <br></br>
                        From our own perspective, any given game of Magic can fall along the following line:
                        <br></br>
                        <br></br>
                        Crushing Loss ------------------Anybody could win/lose-------------------------- Silver Platter Victory
                        <br></br>
                        10:90			  	                                           50:50				                                                           	90:10
                        <br></br>
                        <br></br>
                        The situation where we can’t come back is what I call a “crushing loss”. This type of game is very lopsided and can leave us feeling drained if we are not mentally prepared. We have something like a 10:90 chance of winning.
                        <br></br>
                        <br></br>
                        In the middle stands the “anybody could win or lose”. These are what we are usually aiming for in a classic, good game of Magic. All the deck-power-level-grading methods are aimed to produce these kinds of games. We have an even chance of winning with the other players.
                        <br></br>
                        <br></br>
                        Crushing Loss’s distant sibling, Silver Platter Victory, is also something that doesn’t get mentioned often but that we should be wary of. This type of game is one where we are handed the win on a silver platter- perhaps all our opponents start squabbling with each other and depleting each other's resources; then we just swoop in and take an easy win without much effort. Or perhaps we start with a sol ring, signet opening and we win with a 3 mana advantage on turn 6 against our opponents with 3 available mana. Although we won the game in these cases, the feeling of victory is often not as high because we, the player, actually had little to do with the fact that we won. We had a 90:10 chance of winning.
                        <br></br>
                        <br></br>
                        What we should notice is that not all victories are equal in terms of the feeling of achievement. The silver platter victory offers almost no satisfaction although we technically claim the title of victor. At the same time, our crushing loss offers our opponent little satisfaction (unless they actively enjoy hurting other people, but that is a separate issue). If we switch our perspective and look at the crushing loss from our opponents point of view, they are in fact experiencing just the inverse; hence one player’s crushing loss is often another player’s silver platter victory.
                        <br></br>
                        <br></br>
                        You might then say that nobody benefits from a crushing loss/silver platter victory scenario since the net satisfaction of the playgroup is low. However, I would argue that it is precisely these situations where we could potentially get exponential enjoyment payout, albeit the chances are low.
                        <br></br>
                        <br></br>
                        It turns out that the level of satisfaction we gain from winning a game of Magic is often proportional to the level of challenge we had to overcome to obtain that victory. When we are presented with a 10:90 chance of winning, if we manage to pull off the 10%, then we will have achieved something extraordinary, both in the numerical and the narrative sense. 
                        <br></br>
                        <br></br>
                        We need to consider that when our opponent puts their best foot forward and presents an overwhelming setup for us to overcome, they are in fact presenting us with an opportunity to indulge in a deeper sense of satisfaction, but only if we can rise up to their challenge. Rather than wallowing in sorrow, a losing board state can be recognized as a chance to march towards a more meaningful victory.
                        <br></br>
                        <br></br>
                        All games have winners and losers, but sometimes the losing player has a chance to showcase their finest hour. In these cases, when we, the underdog, attempt to fight against the arch enemy despite the odds, an unexpected victory by our hands can grant an unparalleled sense of satisfaction. These are often the most exciting games of Magic: the Gathering. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/d/3/d3bb726e-e561-4a14-913d-afde1964c64a.jpg?1562644412"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        I’m always looking for chances to snatch a win as the underdog. 
                        <br></br>
                        <br></br>
                        In other words, our next seemingly “unfun” game might just be the precursor to a legendary story about how we came back against all odds. 
                        <br></br>
                        <br></br>
                        Or we might just lose.
                        <br></br>
                        <br></br>
                        Either way, remember that being able to lose is what makes winning fun. And the most epic games often start as crushing losses.
                        <br></br>
                        <br></br>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};