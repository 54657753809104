import { ExperimentOutlined, BuildOutlined, HighlightOutlined, CoffeeOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from "./TopMenu.module.css";


const items = [
    {
      label: <a href='/'> Home </a>,
      key: 'home',
      icon: <ExperimentOutlined />,
    },
    {
      label: <a href="/load"> Load Deck </a>,
      key: 'load',
      icon: <BuildOutlined />,
    },
    {
      label: <a href="/blog" style={{"color": `rgba(255, 255, 255, 0.65)`}}> Blog </a>,
      key: 'blog',
      icon: <HighlightOutlined />,
      children: [
        {
          type: 'group',
          label: 'Guides',
          children: [
            {
              label: <a href="/blog/7_things_i_learned_from_becoming_a_judge_in_2024" style={{"color": `rgba(255, 255, 255, 0.65)`}}> 7 Things I Learned from Becoming a Judge</a>,
              key: 'blog:7_things_i_learned_from_becoming_a_judge_in_2024',
            },
            {
              label: <a href="/blog/tcgplayer_sellers_guide" style={{"color": `rgba(255, 255, 255, 0.65)`}}> Selling on TCGPlayer in 2024 </a>,
              key: 'blog:tcgplayer_sellers_guide',
            },
            {
              label: <a href="/blog/limited_otj" style={{"color": `rgba(255, 255, 255, 0.65)`}}> Leveling Up Limited - OTJ </a>,
              key: 'blog:limited_otj',
            },
          ],
        },
        {
          type: 'group',
          label: 'Opinion',
          children: [
            {
              label: <a href="/blog/command_fest_atlanta" style={{"color": `rgba(255, 255, 255, 0.65)`}}> CommandFest Atlanta 2024 Review</a>,
              key: 'blog:command_fest_atlanta',
            },
            {
              label: <a href="/blog/buying_serialized_cards" style={{"color": `rgba(255, 255, 255, 0.65)`}}> Serial Cards in the Reprint Age </a>,
              key: 'blog:buying_serialized_cards',
            },
            {
              label: <a href="/blog/handling_unfun_decks" style={{"color": `rgba(255, 255, 255, 0.65)`}}> Handling "Unfun" Decks </a>,
              key: 'blog:handling_unfun_decks',
            },
          ],
        },
        {
          type: 'group',
          label: 'Deck Techs',
          children: [
            {
              label: <a href="/blog/picking_a_deck_pauper_edition" style={{"color": `rgba(255, 255, 255, 0.65)`}}> Picking a Deck - Pauper Edition</a>,
              key: 'blog:picking_a_deck_pauper_edition',
            },
            {
              label: <a href="/blog/golgari_ramp_standard_is_back" style={{"color": `rgba(255, 255, 255, 0.65)`}}> Golgari Ramp -  Paper Standard is Back!</a>,
              key: 'blog:golgari_ramp_standard_is_back',
            },
            {
              label: <a href="/blog/orzovh_greasefang_explorer" style={{"color": `rgba(255, 255, 255, 0.65)`}}> Orzovh Greasefang: Explorer </a>,
              key: 'blog:orzovh_greasefang_explorer',
            },
            {
              label: <a href="/blog/tasigur_pod" style={{"color": `rgba(255, 255, 255, 0.65)`}}> Tasigur Pod: Commander </a>,
              key: 'blog:tasgiur_pod',
            },
          ],
        },
      ],
    },
    {
      label: <a href="/about"> About </a>,
      key: 'about', 
      icon: <CoffeeOutlined />,
    }
  ];

  const TopMenu = () => {
    const [current, setNavBarSelected] = useState('navBarSelected');

    const onClick = (e) => {
      setNavBarSelected(e.key)
    };
    let location = useLocation();
    if (current === "navBarSelected") {
      // use location to determine which path to use
      if (location.pathname === "/") {
        setNavBarSelected("home");
      }
      else if (location.pathname === "/load" ) {
        setNavBarSelected("load");
      }
      else if (location.pathname === "/about") {
        setNavBarSelected("about");
      }
      else if (location.pathname === "/blog") {
        setNavBarSelected("blog");
      }
     }
    return (
    <div className={styles.topMenuWrapper}>
      <Menu theme="dark" onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />
    </div>
    );
  };

  export default TopMenu;