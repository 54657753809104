import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';


export function BuyingSerializedLurrusAgeOfReprintsArticle()  {
    const { Content } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px', maxWidth: "100vh"}} className={styles.textbody}>
                        <br></br>
                        <h1>Serial Cards in the Reprint Age</h1>
                        <h3>Published: Feb. 29th 2024 </h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Opinion" color="yellow"/>
                        <br></br>
                        <br></br>
                        Happy February folks - what I’d really like to get down on paper today are my thoughts on a purchase that I made earlier this month, a purchase that does make me somewhat biased on the topic, but I like to think I am open-minded enough to facilitate an honest discussion.
                        <br></br>
                        <br></br>
                        But first off, let’s take a look at the cat in the bag, my relatively expensive purchase for the price of $399 off tcgplayer: Orzhov is my favorite Ravnica guild, I enjoy the mid-range value type decks that this nightmare cat often plays in, and I own two cats; this specific serialized card resonates with me.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/c/2/c2c9065c-54e5-473b-b2cd-f2826d92bc17.jpg?1682485136"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        Note this is just a thumbnail, I was not able to secure #1/500.
                        <br></br>
                        <br></br>
                        When serialized cards first came into mass production circa November 2022, The Brothers War Set had just been released. At that time I admittedly purchased a collectors booster box and then subsequently another one with a gamblers’ hope of pulling a rare serialized card for myself. 
                        <br></br>
                        <br></br>
                        Spoiler alert: life is not a movie, and I found myself down more than $400 for what I can best describe as a glorified lottery ticket. Like most people, I’m not fond of scratch cards and I’m also not the type to daydream too much about unlikelihoods. 
                        <br></br>
                        <br></br>
                        What was it about these serialized cards that had me pulling out my wallet hand-over-fist? 
                        <br></br>
                        <br></br>
                        Perhaps it was the allure of possessing a factually unique collectible, or perhaps it was the thrilling idea of pulling tremendously positive value; the better serialized cards could be a hefty $1000, if you could get lucky off of a ~$230 box.
                        <br></br>
                        <br></br>
                        Either way, I know I’m not the only one who was drawn in by this new marketing scheme.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/d/c/dcdbecfc-fc37-4327-9469-7139e16f7fbd.jpg?1679771896"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        The coveted poster child of serialized cards.
                        <br></br>
                        <br></br>
                        Fast forward a year and a half and we are in February of 2024 now. Several sets of magic cards have come and gone. Not all, but many of them contain serialized cards - March of the Machine (April 2023), Lord of the Rings (June 2023), Doctor Who (October 2023), and most recently Ravnica Remastered (January 2024).
                        <br></br>
                        <br></br>
                        As more and more cards become serialized, we can almost preordain what the future holds. Imagine a world where every popular playable card has a serialized version. For now, the highest serial number is 500, but Wizards of the Coast could easily double that number and then double it again the year after. 
                        <br></br>
                        <br></br>
                        In fact, WotC has already started a ominous precedent of serializing by version of the card rather than the actual card - there are already two serialized versions of Chromatic Lantern at the time of this writing, one from Brothers War schematics, and one from Ravnica Remastered retros.
                        <br></br>
                        <br></br>
                        The case for serial numbered cards remaining valuable over time has many holes in it. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/3/e3868c3d-4fcd-444b-866f-0f8e50ce7b67.jpg?1562479014"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        It's not so hard to see where this is going.
                        <br></br>
                        <br></br>
                        Despite this, I went ahead with the decision to purchase one for myself anyways. 
                        <br></br>
                        <br></br>
                        The way I see it, Magic: The Gathering is so popular for two reasons - the first is that the gameplay is excellent (and that seems to be holding mostly true but there are also holes in that ideal, perhaps another article), and the second is that it is a collectible, one of the most premium collectible games in the world. I have always enjoyed the collecting aspect of Magic, but in an era where reprints of once rare cards have become more and more common, it is difficult to feel justified in keeping a collection of value.
                        <br></br>
                        <br></br>
                        For most cards outside of the reserved list, you are sitting on a flood dam with a hole in it. It’s just a matter of time before WotC reprints your most valuable cards, plummeting their value. It’s also just a matter of time before your favorite cards become strictly power-crept on, further lowering their value - see Dark Confidant and Tarmogoyf. Zenthr actually had a conversation with me not too long ago expressing his desire to sell out his collection since holding it seemed to only lower its value.
                        <br></br>
                        <br></br>
                        I agree that the price of game pieces going down is great for the players, but for the collectors, it doesn’t bode well. In this backdrop of constantly dropping card prices, my purchase of a serialized Lurrus is essentially a different way to collect. I’ve never been a completionist collector, instead I’ve always chosen to be very selective about my favorite pieces of a set and get those, usually in the “best” treatments available. 
                        <br></br>
                        <br></br>
                        WotC can definitely print more serialized cards, and they may even serialize a different version of Lurrus, but they can’t reprint my specific # XXX version of March of the Machine Lurrus in the way that they have been reprinting competitive and commander staples in new sets. That’s my hope at least.
                        <br></br>
                        <br></br>
                        I guess regardless of the monetary value though, the feeling that a piece of my collection is one-of-a-kind is something that I do regard with a degree of reverence.
                        <br></br>
                        <br></br>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};