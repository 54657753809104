import styles from "./blog_styles.module.css";
import {Layout, Image, Badge} from 'antd';


export function OrzovhGreasefangArticle()  {
    const { Content } = Layout;

    return (
        <div style={{ marginLeft: '20px'}} className={styles.wrapper}>
            <Layout>
                <Content style={{ padding: '0 48px' }}>
                    <div style={{ marginLeft: '20px', maxWidth: "100vh"}} className={styles.textbody}>
                        <br></br>
                        <h1>Orzovh Greasefang - Deck Tech Series</h1>
                        <h3>Published: Apr. 22nd 2024 </h3>
                        <h3>Author: xTaq </h3>
                        <Badge count="Explorer" color="cyan"/>
                        <br></br>
                        <br></br>
                        Hello Folks-
                        <br></br>
                        <br></br>
                        Today I’d like to do a spec on a deck that is close to my heart.
                        This old faithful has been core to my MTG: Arena gameplay for the last year and a half 
                        and I even ended up getting the cards for it in paper.
                        <br></br>
                        Thanks to this reliable deck, I’ve weathered every standard release in explorer 
                        since Innistrad: Crimson Vow with virtually no changes 
                        (just the addition of two uncommon removal spells in the past year).
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/4/9455cb27-89d3-4bb1-8af8-08c7712c2915.jpg?1654570167"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/8/7/871279e6-9a59-470e-8acc-fd5fe4fda5b3.jpg?1651655658"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        The Greasefang combo for the uninitiated. 
                        <br></br>
                        <br></br>
                        Here it is if you want to have a quick browse before I do a more thorough walkthrough: 
                        <br></br>
                        <a target="_blank" rel="noopener noreferrer" href="https://sorinslist.com/deck/CabarettiSpiritContraption">Orzovh Greasefang in Explorer</a>
                        <br></br> 
                        <br></br>
                        Originally starting as a budget deck that ran only two colors due to rare wildcard constraints
                        compared to the traditional three colors in the Abzan or Rakdos variants, 
                        this self-brew turned into my most played deck.
                        <br></br> 
                        It plays fast and because the decklist is unique, 
                        it is difficult for opponents to meta-game around it 
                        since there’s no common decklist available online (until now).
                        <br></br>
                        I have never encountered anyone else playing Orzovh Greasefang and 
                        I play this deck online nearly everyday (95% of the time). 
                        <br></br>
                        In other words, this deck is a bit of a diamond in the sand and 
                        I’ve hit mythic with it a half dozen times thanks to its unique resiliency.
                        <br></br>
                        The philosophy of this deck is quite interesting.
                        <br></br>
                        Explorer/pioneer can be a 4-turn format, and in that time, it is rare 
                        to use all the cards in your hand unless you are playing mono-colored aggro.
                        <br></br>
                        <br></br>
                        Therefore, we choose to give up card advantage in favor of board state and resiliency from board wipes.
                        <br></br>
                        The real star of the show, Greasefang’s hidden dragon, is Fleeting Spirit.
                        <br></br> 
                        After this card resolves, you will permanently have it in play since you can always flicker 
                        it until end of turn by profitably discarding a card 
                        (Parhelion II, or any land after your 4th land, or even a flashback card like Can’t Stay Away).
                        <br></br>
                        Simply resolving this card on turn 2 against Azorious control is often a victory since it is unremovable.
                        <br></br>
                        Fleeting Spirit also serves the role of getting our opponents life to 13,
                        the magic number in which the Parhelion II combo one-shots. 
                        <br></br>
                        Compared to its rival, Raffine's Informant, the spirit doesn't draw us any cards but in exchange,
                        it holds the board better, it protects itself, and it can discard Parhelion II at instant speed.
                        <br></br>
                        <br></br>
                        Most importantly, having a Fleeting Spirit in play makes it so that
                        we are at the ready to execute the Parhelion II combo whenever 
                        we draw both Greasefang (or a way to reanimate it) and Parhelion II.
                        <br></br>
                        <br></br>
                       <Image
                            src={"https://cards.scryfall.io/large/front/5/2/52633b9b-342a-4854-a915-8b48fd5d60a5.jpg?1673161399"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        A key enabler that plays many roles well.
                        <br></br>
                        <br></br>
                        To fill the graveyard and set up synergies such as feeding Fleeting Spirit’s first-strike cost,
                        we play self-mill cards like Stitcher’s Supplier and Undead Butler,
                        which we utilize in several synergistic capacities. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/1/0/10e65e74-14fc-4286-84d3-36c70d6dcf46.jpg?1675645189"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/2/c/2c9b8582-8887-4652-82e2-f9b11ee21545.jpg?1643590414"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        Our powerful pawns. 
                        <br></br>
                        <br></br>
                        These zombies discourage combat from our opponents because they block well against aggro,
                        defend our planeswalkers, and can be recycled in so many ways:
                        <br></br>
                        <br></br>
                        - Casting Deadly Dispute after blocking is a lot of value
                        (notably the treasure is a free Revolt setup for Fatal Push)
                        <br></br>
                        - It is generally wrong to block the attacking zombies because of their death triggers,
                        so ninjutsu-ing in our single copy of Biting-Palm Ninja for a surprise thoughtseize effect 
                        is quite good value. 
                        <br></br>
                        Even if the ninja is removed, the most important part of ninjutsu 
                        is that we can recast the zombie for its ETB again.
                        <br></br>
                        - Our zombies actually “ramp” us into casting a cheaper board wipe 
                        (Vanquish the Horde, that is to say, our horde).
                        <br></br>
                        We are more than happy to clear the board since 
                        we typically benefit asymmetricaly from death triggers.
                        <br></br>
                        - Stitcher’s supplier notably costs 1 mana, which means it leaves Sorin, Vengeful Bloodlord
                        to have 3 loyalty to reanimate Greasefang in the turn after it is cast.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/7/3/7373fe95-ad1c-44b9-8c7f-464ce8cbffc6.jpg?1627704524"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/0/3017df51-67ae-4c3d-8210-086f83bc2040.jpg?1654567052"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/e/2/e264615c-eb99-4cb3-844a-2b4a94ba5203.jpg?1634348651"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <Image
                            src={"https://cards.scryfall.io/large/front/3/5/354fe9bd-4ec8-409c-8ce5-b29393f3d169.jpg?1557577268"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        A lot of redundancy and synergy. 
                        <br></br>
                        <br></br>
                        Additionally, this deck has the unique advantage of being able to discard Parhelion II
                        at instant speed as opposed to sorcery speed like most Abzan decks.
                        <br></br>
                        This helps play around sorcery speed graveyard hate like Graveyard Trespasser and Go Blank. 
                        <br></br>
                        <br></br>
                        We utilize the following three discard outlets, which all double as either extra removal or an extra blocker.
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/5/2/52633b9b-342a-4854-a915-8b48fd5d60a5.jpg?1673161399"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/0/5/05bdd22c-3e11-4c29-bdfa-d3dfc0e90a9f.jpg?1699044085"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/a/c/ac506c17-adc8-49c6-9d8d-43db7cb1ec9d.jpg?1562835390"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        The Flexible Enablers. 
                        <br></br>
                        <br></br>
                        This deck stalls the board while using planeswalkers or efficent creatures to set up a finisher.
                        <br></br>
                        Also don’t discount the fact that Sorin gives our creatures lifelink on our turn, 
                        which enables a 26-point life swing: an immediate win against aggro 
                        and a solid lead against other midrange decks (notably coco angels). 
                        <br></br>
                        <br></br>
                        As backup for our superfriends, we run the black premium suite of interaction in Pioneer/Explorer
                        in tandem with our Bitter Triumphs and a single Board Wipe. 
                        <br></br>
                        <br></br>
                        <Image
                            src={"https://cards.scryfall.io/large/front/9/5/95702503-8f2d-46c8-abdb-6edd6c431d19.jpg?1599711020"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        &nbsp;
                        <Image
                            src={"https://cards.scryfall.io/large/front/b/9/b9a50516-a20f-4e6e-b4f2-0049b673f942.jpg?1599711004"}
                            preview={true}
                            height={"25vh"}
                            className={styles.card}
                        />
                        <br></br>
                        The usual suspects.
                        <br></br>
                        <br></br>
                        The only thing I sometimes complain about is not seeing my Parhelion II or Greasefang
                        after digging 15+ cards with looped Stitcher’s Suppliers, but hey, that’s Magic!
                        <br></br>
                        <br></br>
                    </div>
                </Content>
            </Layout>
        </div>
    );
};